import { createSlice } from '@reduxjs/toolkit';
import { IRoutingRule, IRoutingRuleSet } from '../models/RoutingRuleSetModel';
import { RoutingRulesAlert } from '../models/RoutingRulesAlert';


export type RoutingRulesData = {
    routingRuleSet: IRoutingRuleSet,
    changedRoutingRules: ChangedRoutingRule[],
    apiCallInProgress: boolean
    alert: RoutingRulesAlert | null;
};

type ChangedRoutingRule = {
    ruleId: string,
    originalRule?: IRoutingRule
}

const initialRoutingRulesState: RoutingRulesData = {
    routingRuleSet: {
        name: "",
        description: "",
        timestamp: "",
        version: 0,
        decisionRules: []
    },
    changedRoutingRules: [],
    apiCallInProgress: false,
    alert: null
};

export const callTypes = {
    get: 'get',
    post: 'post'
};

export const routingRulesSlice = createSlice({
    name: 'routingRulesData',
    initialState: initialRoutingRulesState,
    reducers: {
        catchError: (state, action) => {
            state.alert = action.payload.alert;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post) {
                state.apiCallInProgress = false;
            }
        },
        startCall: (state, action) => {
            state.alert = null;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post) {
                state.apiCallInProgress = true;
            }
        },
        dismissAlert: (state) => {
            state.alert = null;
        },
        routingRulesFetched: (state, action) => {
            state.apiCallInProgress = false;
            state.alert = null;
            state.routingRuleSet = action.payload;
        },
        deleteRoutingRule: (state, action) => {
            const ruleId = action.payload
            state.routingRuleSet.decisionRules = state.routingRuleSet.decisionRules.filter((rule) => rule.id !== ruleId)
        },
        markRoutingRuleChanged: (state, action) => {
            const ruleId = action.payload.ruleId
            const isDeletion = action.payload.isDeletion
            //changed rule is not yet found in changes list, so it needs to be added
            if (!(state.changedRoutingRules.some((changedRule) => changedRule.ruleId === ruleId))) {
                const originalRule = state.routingRuleSet.decisionRules.find((rule) => rule.id === ruleId)
                state.changedRoutingRules.push({
                    ruleId: ruleId,
                    originalRule: originalRule
                })
            }
            //deleted rule is already present in the changes list, and is either new or a clone, so it no longer is a change
            if (isDeletion) {
                const deletedChangeRecord = state.changedRoutingRules.find((rule) => rule.ruleId === ruleId)
                if (deletedChangeRecord && !deletedChangeRecord.originalRule) {
                    state.changedRoutingRules = state.changedRoutingRules.filter((rule) => rule.ruleId !== ruleId)
                }
            }
        },
        clearRoutingRuleChanges: (state) => {
            state.changedRoutingRules = []
        },
        upsertRoutingRule: (state, action) => {
            const newRule = action.payload;
            const ruleIsNew = state.routingRuleSet.decisionRules.find((stateRule) => stateRule.id === newRule.id) === undefined
            const needToReorderSequence = state.routingRuleSet.decisionRules.find((stateRule) =>
                stateRule.sequenceNumber === newRule.sequenceNumber && stateRule.id !== newRule.id) !== undefined
            const insertAtEnd = newRule.sequenceNumber < 0
            if (insertAtEnd) {
                //Calculate sequenceNumber for the rule to be upserted
                const maxSequenceNumber = Math.max(...state.routingRuleSet.decisionRules.map((r) => r.sequenceNumber))
                newRule.sequenceNumber = maxSequenceNumber + 1
            } else if (needToReorderSequence) {
                //Push all rules with a higher sequenceNumber down a spot to create room
                state.routingRuleSet.decisionRules = state.routingRuleSet.decisionRules.map((currentRule) => {
                    if (currentRule.sequenceNumber >= newRule.sequenceNumber && currentRule.id !== newRule.id) {
                        currentRule.sequenceNumber++
                    }
                    return currentRule;
                });
            }
            if (ruleIsNew) {
                //New rule will be inserted according to its sequenceNumber
                state.routingRuleSet.decisionRules.push(newRule)
            } else {
                //Replace current rule with new rule
                state.routingRuleSet.decisionRules = state.routingRuleSet.decisionRules.map((currentRule) => {
                    if (currentRule.id === newRule.id) {
                        return newRule;
                    }
                    return currentRule;
                });
            }
        },
        updateRuleSetDescription: (state, action) => {
            const description = action.payload
            state.routingRuleSet.description = description
        }
    }
});