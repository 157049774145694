import React, { createContext, ReactNode, useState, useContext } from "react";
import { conditionTypes, IRoutingRule } from "./models/RoutingRuleSetModel";

type routingRuleContextProps = {
    selectedRoutingRule: SelectedRoutingRule,
    setSelectedRoutingRule: (selectedRoutingRule: SelectedRoutingRule) => void,
    uiEvents: RoutingRuleUIEvents
}

type RoutingRuleUIEvents = {
    showRuleDetailsModal: () => void,
    hideRuleDetailsModal: () => void,
    showRuleEditModal: () => void,
    hideRuleEditModal: () => void
}

export enum SelectionReason {
    Default = "default",
    New = "new",
    Clone = "clone"
}

type SelectedRoutingRule = {
    routingRule: IRoutingRule,
    selectionReason: SelectionReason
}

const initialRoutingRule = {
    id: "",
    name: "",
    sequenceNumber: 0,
    conditions: {
        conditionType: conditionTypes.conditionGroup
    },
    decision: []
}

const initialSelection = {
    routingRule: initialRoutingRule,
    selectionReason: SelectionReason.Default
} as SelectedRoutingRule

const initialState: routingRuleContextProps = {
    selectedRoutingRule: initialSelection,
    setSelectedRoutingRule: () => { },
    uiEvents: {
        showRuleDetailsModal: () => { },
        hideRuleDetailsModal: () => { },
        showRuleEditModal: () => { },
        hideRuleEditModal: () => { }
    }
};

const RoutingRuleContext = createContext<routingRuleContextProps>(initialState);

export function useRoutingRuleContext() {
    return useContext(RoutingRuleContext);
}

type Props = {
    children: ReactNode,
    routingRulesUIEvents: RoutingRuleUIEvents
};

const RoutingRuleProvider = (props: Props) => {
    const [selectedRoutingRule, setSelectedRoutingRule] = useState<SelectedRoutingRule>(initialSelection);
    const value = {
        selectedRoutingRule,
        setSelectedRoutingRule,
        uiEvents: props.routingRulesUIEvents
    };
    return <RoutingRuleContext.Provider value={value}>{props.children}</RoutingRuleContext.Provider>;
}

export default RoutingRuleProvider;