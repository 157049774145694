import { useState } from "react";
import RoutingRulesCard from "../components/RoutingRulesCard";
import RoutingRuleProvider from "../RoutingRuleContext";
import RoutingRuleDetailModal from "../components/detailModal/RoutingRuleDetailModal";
import RoutingRuleEditModal from "../components/editModal/RoutingRuleEditModal";
import RoutingRulesSaveCard from "../components/RoutingRulesSaveCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RoutingRulesData } from "../redux/RoutingRulesSlice";
import { Alert } from "react-bootstrap";
import * as actions from '../redux/RoutingRulesActions'

const RoutingRulesPage = () => {
  const [showRuleDetailsModal, setShowRuleDetailsModal] = useState<boolean>(false);
  const [showRuleEditModal, setShowRuleEditModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { routingRulesData } = useSelector(
    (state: any) => ({
      routingRulesData: state.routingRulesData as RoutingRulesData
    }),
    shallowEqual
  );
  const alert = routingRulesData.alert

  const handleShowRuleDetailsModal = () => {
    setShowRuleDetailsModal(true)
  }

  const handleHideRuleDetailsModal = () => {
    setShowRuleDetailsModal(false)
  }

  const handleShowRuleEditModal = () => {
    setShowRuleEditModal(true)
  }

  const handleHideRuleEditModal = () => {
    setShowRuleEditModal(false)
  }

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert())
  }

  const routingRulesUIEvents = {
    showRuleDetailsModal: handleShowRuleDetailsModal,
    hideRuleDetailsModal: handleHideRuleDetailsModal,
    showRuleEditModal: handleShowRuleEditModal,
    hideRuleEditModal: handleHideRuleEditModal
  }

  return (
    <RoutingRuleProvider routingRulesUIEvents={routingRulesUIEvents}>
      {alert && alert?.message && (
        <Alert variant="danger" onClose={handleDismissAlert} dismissible>
          <Alert.Heading>{alert.message}</Alert.Heading>
          {alert.url && <div>{`Call to ${alert.url}`}</div>}
          {alert.apiMessage && <div>{`Response: ${alert.apiMessage}`}</div>}
          {alert.apiResponseCode && <div>{`ResponseCode: ${alert.apiResponseCode}`}</div>}
        </Alert>
      )}
      <RoutingRulesSaveCard />
      <RoutingRulesCard />
      {showRuleDetailsModal && <RoutingRuleDetailModal />}
      {showRuleEditModal && <RoutingRuleEditModal />}
    </RoutingRuleProvider>
  )
}

export { RoutingRulesPage };